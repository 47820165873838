import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import FeaturesIcon from "../assets/svg/FeaturesIcon.svg";

// Animation variants
const fadeUpVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const gridVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.1, duration: 0.6, ease: "easeOut" },
  }),
};

const FeatureCard = ({ feature, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-30% 0px -40% 0px",
    once: false,
  });

  return (
    <motion.div
      ref={ref}
      className={`p-4 feature-card space-y-2 ${isInView && window.innerWidth <= 768 ? "hover-active" : ""
        }`}
      custom={index}
      variants={gridVariants}
      transition={{ duration: 0.3 }}
    >
      <p className="text-[13px] card-heading font-semibold text-[#13fb73]">
        {feature.title}
      </p>
      <p className="text-[14px] card-desc font-normal text-[#c4efea]">
        {feature.description}
      </p>
    </motion.div>
  );
};

const Features = () => {
  const features = [
    {
      title: "One-Stop Hub for Digital Presence",
      description:
        "Sudo Lab AI agent offers domain management, hosting, cloud services, IoT, Node as a Service, RDP, and more in one place, so you don’t have to juggle between platforms.",
    },
    {
      title: "Advanced AI & ML Integration",
      description:
        "Sudo Lab empowers AI agents with state-of-the-art models, such as GPT-4 for conversational intelligence, BERT for NL understanding and AlphaZero for advanced decision-making tasks.",
    },
    {
      title: "Future-Proof with $SUDO Token",
      description:
        "Sudo Lab AI Agent is launching with the $SUDO token. Access to the agent is currently open to all, but may become token-gated in the future. Secure your token now for guaranteed access.",
    },
    {
      title: "Future Plan",
      description:
        "The team is developing the AI Agent DApp to enhance its capabilities and accessibility, with plans for continuous improvements based on community feedback.",
    },
    {
      title: "IoT & Data Solutions",
      description:
        "Explore our decentralized IoT solutions to join sensor networks like Helium, earn rewards, and manage digital needs while enhancing connectivity and data insights.",
    },
    {
      title: "Cloud Infrastructure Solution",
      description:
        "Take control of your cloud infrastructure with Sudo Lab. Seamlessly deploy blockchain nodes, containers, and Node as a Service, while managing storage, CDN, and AI agents with ease.",
    },
  ];

  return (

    // <section id="get-started"> <
    <motion.div
      className="relative flex flex-col w-full items-center justify-between sm:px-8 px-4 md:px-12 py-16 md:py-24"
      initial="hidden"
      whileInView="visible"
      id="features"

      viewport={{ once: true, amount: 0.3 }}
      variants={fadeUpVariants}
    >
      <img
        src={FeaturesIcon}
        alt="features-icon"
        className="absolute md:right-0 right-auto left-0 md:left-auto md:translate-x-[-100%] translate-x-0 sm:translate-x-[100%] top-0 lg:translate-y-0 md:translate-y-[-50%] translate-y-[-100%]"
      />
      <p className="text-[13px] font-semibold text-[#13fb73]">Features</p>
      <p className="text-[32px] text-center font-semibold text-transparent bg-clip-text bg-gradient-to-r leading-tight">
        Achieve More Without the Grind
      </p>
      <p className="text-[14px] text-center font-normal text-[#c4efea]">
      With Sudo Lab AI Agent, you’ll have everything you need on one platform to make your digital presence seamless.
      </p>

      {/* Grid of feature cards */}
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-0 md:px-8 lg:px-12 py-8"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        {features.map((feature, index) => (
          <FeatureCard key={index} feature={feature} index={index} />
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Features;
