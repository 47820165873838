import { motion, useInView } from "framer-motion"; // Import Framer Motion and useInView
import React, { useRef } from "react";
import { SVGs } from "../assets/svg";
import HeroBG from "../assets/svg/HeroBG.svg";
import { Link } from "react-router-dom";

// Animation variants
const fadeInLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6, ease: "easeInOut" },
  },
};

const buttonVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.4, duration: 0.8, ease: "easeOut" },
  },
};

const initialIconReveal = {
  hidden: { opacity: 0, rotate: 0 },
  visible: {
    opacity: 1,
    rotate: 0,
    transition: { duration: 0.8, ease: "easeInOut" },
  }, // Initial reveal
};

const infiniteRotateIcon = {
  rotate: 360,
  transition: {
    repeat: Infinity,
    duration: 8, // Slower spin
    ease: "linear",
  },
};

const fadeInRight = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6, ease: "easeInOut" },
  },
};

const springBulletLeftToRight = {
  hidden: { opacity: 0, x: 100, scale: 0.8 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 120,
      damping: 15,
      delay: i * 0.2,
    },
  }),
};

const Hero = () => {
  const bulletData = [
    {
      title: "Domain & Hosting",
      description:
        "Sudo Lab AI Agent helps you with domain management and offers numerous hosting options, such as email, VPS, shared, and dedicated hosting.",
    },
    {
      title: "IoT and Data Solution",
      description:
        "Sudo Lab AI Agent connects you to a decentralized network of smart sensors and devices to boost your connectivity and earn rewards along the way.",
    },
    {
      title: "Containerization",
      description:
        "Deploying applications has never been easier! Our AI agent can handle Kubernetes and serverless containers so you can focus on growth.",
    },
    {
      title: "Cloud Solutions",
      description:
        "The AI Agent lets you deploy and manage blockchain nodes, Node as a Service, IoT, data solutions, CDN, and more within minutes.",
    },
    {
      title: "RDP Services",
      description:
        "Access your desktop from anywhere with Sudo Lab’s RDP services for seamless file transfer and multi-user setups.",
    },
    {
      title: "Website Development",
      description:
        "The Sudo Lab AI Agent designs and crafts a fully functional site tailored to your needs by simply answering a few prompts.",
    },
  ];

  const ServiceBullet = ({ bullet, index }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
      margin: "-30% 0px -40% 0px",
      once: false,
    });

    return (
      <motion.div
        key={index}
        ref={ref}
        className={`relative group ${isInView && window.innerWidth <= 768 ? "bullet-active" : ""
          }`}
        custom={index}
        initial="hidden"
        whileInView="visible"
        variants={springBulletLeftToRight}
        viewport={{ once: true, amount: 0.3 }}
      >
        <span className="hero-bullets-wrapper relative">
          <span className="hero-bullets-bg"></span>
          <p className="hero-bullets text-[16px] relative font-normal text-[#c4efea] px-3 z-10">
            {bullet.title}
          </p>
          <motion.p className="bullet-description z-10 px-3">
            {bullet.description}
          </motion.p>
        </span>
      </motion.div>
    );
  };

  return (
    <section className="relative flex flex-col md:flex-row items-center justify-between md:gap-0 gap-12 px-6 lg:px-12 pt-16 md:pt-36 pb-24">
      <SVGs.HeroLightSVG className="hero-light" />
      <img
        alt="hero-bg"
        src={HeroBG}
        className="absolute left-0 top-[-1.5rem]"
      />

      {/* Left Column - Heading, Description, and Buttons */}
      <motion.div
        className="flex flex-col items-start text-left space-y-4 md:w-1/3 z-10"
        initial="hidden"
        animate="visible"
        variants={fadeInLeft} // Fade in the left section
      >
        <h1 className="text-[36px] font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 font-['Noto Serif Thai'] leading-tight">
          Fuel Your Digital Journey with Sudo Lab AI Agents
        </h1>

        <p className="text-[14px] font-normal text-[#c4efea]">
        Sudo Lab is an advanced AI agent designed to streamline and  
          <br />  enhance domain, hosting, blockchain, RDP, IoT, and cloud services.


        </p>

        {/* Buttons with fade-in and upwards movement */}
        <motion.div
          className="flex gap-4 flex-wrap ms-3"
          initial="hidden"
          animate="visible"
          variants={buttonVariants} // Upwards movement for buttons
        >
          <button
            className="relative inline-block px-8 py-2 text-black bg-button-gradient hover:bg-button-linear transition-all ease-out duration-400  transform skew-x-[-30deg] border-none"
            style={{
              backgroundSize: "175% 100%",
              backgroundPosition: "center",
            }}
            onClick={() => window.open("https://t.me/SudoLabAIAgentBot", "_blank")}
          >
            <span className="inline-block transform whitespace-nowrap skew-x-[30deg]">
              Launch AI Agent
            </span>
          </button>

          <Link to="#features">
            <button
              style={{
                backgroundSize: "175% 100%",
                backgroundPosition: "center",
              }}
              className="relative outline-btn inline-block px-8 py-2 text-[#13FB72] transform skew-x-[-30deg]"
            >
              <span className="inline-block transform skew-x-[30deg]">

                Learn more


              </span>
            </button>
          </Link>
        </motion.div>
      </motion.div>

      {/* Middle Icon - Initial Reveal and then Infinite Slow Rotation */}
      <motion.div
        className="z-10"
        initial="hidden"
        animate="visible"
        variants={initialIconReveal} // Initial reveal
      >
        <motion.div
          animate={infiniteRotateIcon} // Infinite slow rotation
        >
          <SVGs.HeroIconSVG />
        </motion.div>
      </motion.div>

      {/* Right Column - Heading and Bullet Points */}
      <motion.div
        className="flex flex-col ms-auto md:ms-0 items-end text-right space-y-2 md:w-1/3 z-10"
        initial="hidden"
        animate="visible"
        variants={fadeInRight} // Fade in the right section
      >
        <p className="text-[13px] font-semibold text-[#13fb73] tracking-widest">
          Our Services
        </p>

        <h2 className="text-[36px] font-semibold text-transparent bg-clip-text bg-gradient-to-l leading-tight">
          One AI Agent for All Needs
        </h2>
        <p className="text-[14px] font-normal text-[#c4efea]">
        Why juggle platforms when Sudo Lab AI Agent can streamline your workflow, saving time and effort?
        </p>

        {/* Bullet Points Snapping in from Left to Right with Spring Motion */}
        {bulletData.map((bullet, index) => (
          <ServiceBullet bullet={bullet} index={index} />
        ))}
      </motion.div>
    </section>
  );
};

export default Hero;
