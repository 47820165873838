import React from "react";
import { motion } from "framer-motion";
import { SVGs } from "../assets/svg";
import { Link } from "react-router-dom";

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const staggeredLinks = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.1, duration: 0.5, ease: "easeOut" },
  }),
};

// Reusable Link Component
const FooterLink = ({ label, link }) => (
  <motion.p
    className="text-[#AEAEAE] text-[14px] md:text-[16px] font-light hover:text-[#c4efea] transition-all ease-out"
    initial="hidden"
    animate="visible"
    variants={fadeInUp}
  >
    <Link to={link}>
      {label}
    </Link>
  </motion.p>
);

const Footer = () => {
  const businessLinks = [
    { name: "Features", link: "#features" },
    { name: "Tokenomics", link: "#tokenomics" },
    { name: "Roadmap", link: "#roadmap" },
    // { name: "Platforms", link: "#platforms" },
    { name: "Platforms", link: "" },

    // { name: "Whitepaper", link: "#whitepaper" },
  ];

  const productLinks = [
    { name: "Cloud", link: "#features" },
    { name: "RDP", link: "#features" },
    { name: "Web Development", link: "#features" },
    { name: "Hosting", link: "#features" },
    { name: "Domain Management", link: "#features" },
    { name: "Containerization", link: "#features" },
  ];
  

  return (
    <motion.div
      className="flex flex-col bg-[#020913] px-6 md:px-16 py-8 md:py-12 w-full"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={fadeInUp}
    >
      {/* Top Section */}
      <motion.div
        className="flex flex-col md:flex-row items-center justify-between py-4 w-full border-b-[1px] border-[#13fb73]"
        variants={fadeInUp}
      >
        <SVGs.FooterLogoSVG />
        <motion.div className="flex items-center gap-4 md:gap-8 mt-4 md:mt-0">
          <a
            href="https://x.com/sudolab_"
            target="_blank"
            rel="noreferrer"
          >
            <SVGs.TwitterSVG
              height={"28px"}
              width={"28px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a href="https://t.me/sudolabs" target="_blank" rel="noreferrer">
            <SVGs.TelegramSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a href="https://dexscreener.com/ethereum/0xa9cf7ce1689c4ac732dcdcb3022348667c937200" target="_blank" rel="noreferrer">
            <SVGs.DexScreenerSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xa9cf7ce1689c4ac732dcdcb3022348667c937200"
            target="_blank"
            rel="noreferrer"
          >
            <SVGs.OtherIconSVG
              height={"32px"}
              width={"32px"}
              className="fill-[#878693] hover:fill-[#39CB75]"
            />
          </a>
        </motion.div>
      </motion.div>

      {/* Middle Section */}
      <div className="flex flex-row md:flex-nowrap flex-wrap mt-8 items-start justify-start relative w-full gap-8 md:gap-12">
        <SVGs.FooterIconSVG className="absolute right-0 bottom-0" />

        {/* Business Links */}
        <div className="flex flex-col gap-4">
          <p className="text-[#39CB75] text-[20px] md:text-[24px] font-extrabold tracking-wide">
            Business
          </p>
          {businessLinks.map((link, index) => (
            <FooterLink
              key={index}
              label={link?.name} link={link?.link} />
          ))}


          <motion.p
            className="text-[#AEAEAE] text-[14px] md:text-[16px] font-light hover:text-[#c4efea] transition-all ease-out"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <a href={"https://whitepaper-v2.sudolab.net"} target="_blank">
              Whitepaper
            </a>

          </motion.p>
        </div>

        {/* Product Links */}
        <div className="flex flex-col gap-4">
          <p className="text-[#39CB75] text-[20px] md:text-[24px] font-extrabold tracking-wide">
            Solutions
          </p>
          {productLinks.map((link, index) => (
            <FooterLink key={index} label={link?.name} link={link?.link}/>
          ))}
        </div>
      </div>

      {/* Bottom Section */}
      <motion.p
        className="text-[12px] text-[#0b691e] w-full text-center mt-8"
        variants={fadeInUp}
      >
        Copyright © 2024 Sudo Lab - All Rights Reserved.
      </motion.p>
    </motion.div>
  );
};

export default Footer;
